import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import { getBookedMeetingUidAPI, postBookingCancelAPI } from '../../api/api';
import { GlobalContext } from '../../context/GlobalContext';
import phone from '../../assets/images/phone1.png';
import styles from './Reschedule.module.scss';
import InterviewConfirmedContacts from '../../components/InterviewConfirmed/InterviewConfirmedContacts';
import ButtonError from '../../components/UI/ButtonError';
import ArrowIcon from '../../components/Icons/ArrowIcon';
import ArrowLeftIcon from '../../components/Icons/ArrowLeftIcon';
import { objectToUrl, urlToObject } from '../../components/utils/url';

const Reschedule = () => {
  const [type, setType] = useState(1);
  const [user, setUser] = useState(null);

  const { selectedTime, setSelectedTime, translate, environmentConfig } = useContext(GlobalContext);

  const location = useLocation();
  const navigate = useNavigate();

  const getUser = async (uid) => {
    const result = await getBookedMeetingUidAPI(uid);
    if (result) {
      setSelectedTime(result.start_at);
      const dataUser = {
        meeting_uid: result.uid,
        phone: result.lead_phone,
        email: result.lead_email,
        lastName: result.lead_last_name,
        firstName: result.lead_first_name,
        course: result.course?.title
      };

      const infoUrl = urlToObject(location.search);

      if (infoUrl.createdBy) {
        dataUser.createdBy = infoUrl.createdBy;
      }
      setUser(dataUser);
    } else {
      navigate('/error');
    }
  };

  const handlerClickBtn = async () => {
    if (type === 1) {
      navigate(`/${objectToUrl(user)}`);
    }
    if (type === 2) {
      const result = await postBookingCancelAPI(user.meeting_uid);
      if (!result) {
        navigate('/error');
      } else {
        navigate(`/cancel-the-meeting/${objectToUrl(user)}&interview=canceled`);
      }
    }
    if (type === 3) {
      if (process.env.REACT_APP_DESIGN === 'Skillium') {
        window.location.href = 'https://skillium.org/';
      } else {
        window.location.href = environmentConfig.URL_MAIN_ELVTR;
      }
    }
  };

  useEffect(() => {
    if (location.pathname.includes('/reschedule')) {
      getUser(location.pathname.slice(12));
      setType(1);
    } else if (location.pathname.includes('/cancel-the-meeting')) {
      setUser(urlToObject(location.search));
      const userInfo = urlToObject(location.search);
      if (userInfo.interview) {
        setType(3);
      } else {
        setType(2);
      }
    }
  }, [location]);

  return (
    <div className={styles.reschedule}>
      <div className={`${styles.reschedule__head} ${type === 3 && styles.reschedule__confirm}`}>
        <div className={styles.reschedule__head__title}>
          <h1>Consultation booked</h1>
          <p>
            <img src={phone} alt="phone" />
            {translate.TITLE_IN_CONSULTATION_PAGE}
          </p>
        </div>
        <InterviewConfirmedContacts
          course={user?.course}
          date={selectedTime}
          tel={user?.phone}
          email={user?.email}
        />
      </div>
      <div className={styles.reschedule__messenger}>
        {type === 2 && <p>Are you sure you want to cancel the consultation?</p>}
        {type === 3 && <p>Consultation has been canceled.</p>}
      </div>
      <div className={styles.reschedule__btn}>
        <ButtonError onClick={handlerClickBtn}>
          {type === 1 && translate.RESCHEDULE_BUTTON}
          {type === 2 && 'Confirm'}
          {type === 3 && 'Main page'}
          <ArrowIcon />
        </ButtonError>
        {type === 2 && (
          <ButtonError onClick={() => navigate(-1)} type="back">
            <ArrowLeftIcon /> Back
          </ButtonError>
        )}
        {type === 1 && user && (
          <Link to={`/cancel-the-meeting/${objectToUrl(user)}`}>Cancel the meeting</Link>
        )}
      </div>
    </div>
  );
};

export default Reschedule;
