import { format } from 'date-fns';
import styles from './BookMeetingTime.module.scss';

const BookMeetingTime = ({ title, onClick }) => {
  return (
    <button type="button" onClick={onClick} className={styles.BookMeetingTime}>
      <h3>{format(new Date(title), 'hh:mm a')}</h3>
    </button>
  );
};

export default BookMeetingTime;
