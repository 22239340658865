import styles from './ButtonError.module.scss';

const ButtonError = ({ onClick, children, type }) => {
  return (
    <button type="button" className={`${styles.buttonError} ${styles[type]}`} onClick={onClick}>
      {children}
    </button>
  );
};

export default ButtonError;
