export const TIMEZONE_US_CANADA = [
  {
    id: 1,
    region: 'Pacific Time - US & Canada',
    timeZone: 'America/Los_Angeles',
    gmt: 'GMT-08:00'
  },
  {
    id: 2,
    region: 'Mountain Time - US & Canada',
    timeZone: 'America/Denver',
    gmt: 'GMT-07:00'
  },
  {
    id: 3,
    region: 'Central Time - US & Canada',
    timeZone: 'America/Chicago',
    gmt: 'GMT-06:00'
  },
  {
    id: 4,
    region: 'Eastern Time - US & Canada',
    timeZone: 'America/New_York',
    gmt: 'GMT-05:00'
  },
  {
    id: 5,
    region: 'Alaska Time - US',
    timeZone: 'America/Anchorage',
    gmt: 'GMT-09:00'
  },
  {
    id: 6,
    region: 'Newfoundland Time - Canada',
    timeZone: 'America/St_Johns',
    gmt: 'GMT-04:00'
  },
  {
    id: 7,
    region: 'Hawaii Time - US',
    timeZone: 'Pacific/Honolulu',
    gmt: 'GMT-10:00'
  }
];

export const TIMEZONE_EUROPE = [
  {
    id: 8,
    region: 'Central European Time',
    timeZone: 'Europe/Warsaw',
    gmt: 'GMT+01:00'
  },
  {
    id: 9,
    region: 'Eastern European Time',
    timeZone: 'Europe/Kiev',
    gmt: 'GMT+02:00'
  },
  {
    id: 10,
    region: 'UK, Ireland, Lisbon Time',
    timeZone: 'Europe/Lisbon',
    gmt: 'GMT+00:00'
  }
];
