import { format } from 'date-fns/esm';
import { useContext, useMemo } from 'react';
import CalendarIcon from '../Icons/CalendarIcon';
import EmailIcon from '../Icons/EmailIcon';
import StudentIcon from '../Icons/StudentIcon';
import TelephoneIcon from '../Icons/TelephoneIcon';
import TimeIcon from '../Icons/TimeIcon';
import styles from './InterviewConfirmedContacts.module.scss';
import { GlobalContext } from '../../context/GlobalContext';
import SkilliumClockIcon from '../Icons/SkilliumClockIcon';
import SkilliumPhoneIcon from '../Icons/SkilliumPhoneIcon';
import SkilliumEmailIcon from '../Icons/SkilliumEmailIcon';

const InterviewConfirmedContacts = ({ date, tel, email, course, timezone }) => {
  const { marketFormat, translate } = useContext(GlobalContext);

  const timezoneShort = useMemo(() => {
    switch (timezone) {
      case 'Pacific Time - US & Canada':
        return 'PDT';
      case 'Mountain Time - US & Canada':
        return 'MDT';
      case 'Central Time - US & Canada':
        return 'CDT';
      case 'Eastern Time - US & Canada':
        return 'EDT';
      case 'Alaska Time - US':
        return 'AKDT';
      case 'Newfoundland Time - Canada':
        return 'NDT';
      case 'Hawaii Time - US':
        return 'HST';
      case 'Central European Time':
        return 'CEST';
      case 'Eastern European Time':
        return 'EEST';
      case 'UK, Ireland, Lisbon Time':
        return translate.BRITISH_TIMEZONE_ABBREVIATION;
      default:
        return '';
    }
  }, [JSON.stringify(timezone), translate]);
  return (
    <div className={styles.contacts}>
      {course && (
        <div
          className={`${styles.contacts__slilliumTheme__header} ${styles.contacts__slilliumTheme}`}>
          <p>{course}</p>
        </div>
      )}
      <div className={styles.contacts__info}>
        {course && (
          <div className={`${styles.contacts__info__item} ${styles.contacts__elvtrTheme}`}>
            <StudentIcon /> <p>{course}</p>
          </div>
        )}
        <div className={`${styles.contacts__info__item} ${styles.contacts__slilliumTheme}`}>
          <SkilliumClockIcon />
          <p className={styles.contacts__info__item__date}>
            {format(new Date(date), "ccc (d/M) 'AT' H:mm a")}
          </p>
        </div>
        <div className={`${styles.contacts__info__item} ${styles.contacts__elvtrTheme}`}>
          <CalendarIcon />
          <p> {format(new Date(date), marketFormat.TIME_FORMAT_CONFORMATION_PAGE)}</p>
        </div>
        <div className={`${styles.contacts__info__item} ${styles.contacts__elvtrTheme}`}>
          <TimeIcon />
          <p>
            {format(new Date(date), 'hh:mm a')} {timezoneShort}
          </p>
        </div>
        <div className={styles.contacts__info__item}>
          <TelephoneIcon />
          <SkilliumPhoneIcon />
          <p> {tel}</p>
        </div>
        <div className={styles.contacts__info__item}>
          <EmailIcon />
          <SkilliumEmailIcon />
          <p> {email}</p>
        </div>
      </div>
    </div>
  );
};

export default InterviewConfirmedContacts;
